import React from "react";
import { withPrefix } from "gatsby";

const Banner = (props) => {
  const { title = "" } = props;
  return (
    <section className="rvc-banner">
      <div className="banner_bg" />
      <h1 className="banner_title">{title}</h1>
      <img
        className="banner_logo"
        src={`${withPrefix("/")}img/ravel-logo.png`}
        alt="logo"
      />
    </section>
  );
};

export default Banner;
