export default {
  author: "雷弗資訊有限公司",
  title: "雷弗資訊",
  description:
    "雷弗資訊創立於2019年3月，致力於跨平台的快速軟體開發、概念驗證，提供客製化的軟硬整合服務。",
  navbar: {
    service: "服務",
    about: "關於",
    contact: "聯絡我們",
    blog: "專欄",
    jobs: "徵才",
  },
  copyright: "著作權 © 2019 雷弗資訊有限公司 版權所有。",
};
