import React from "react";
import { graphql } from "gatsby";

import Jobs from "../components/Jobs";

import metaData from "../languages/zh/metaData";
import dictionary from "../languages/zh/jobs";

const PageJobsZhQuery = graphql`
  query PageJobsZh($type: String) {
    allMarkdownRemark(
      limit: 10
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { templateType: { eq: $type } } }
    ) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            templateKey
            templateType
            date
            title
            location
            requirement
            links {
              name
              url
            }
          }
        }
      }
    }
  }
`;

const PageJobsZh = (props) => {
  const { edges } = props.data.allMarkdownRemark;
  return (
    <Jobs lang="zh" metaData={metaData} dictionary={dictionary} data={edges} />
  );
};

export default PageJobsZh;
export { PageJobsZhQuery };
