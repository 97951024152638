import React from "react";

import reviseIsoString from "../lib/reviseIsoString";
import "../plugins/fontawesome";

import * as Grid from "../modules/grid";
import { Layout } from "../modules/layout";
import { Banner } from "../modules/banner";
import { Divider } from "../modules/divider";
import { Button } from "../modules/button";
import { Card } from "../modules/card";

const Jobs = (props) => {
  const { lang, metaData, dictionary, data } = props;

  const _redirectJob = (title) => {
    window.location.href = `${
      window.location.origin
    }/${lang}/job/${title.toLowerCase()}`;
  };

  return (
    <Layout lang={lang} metaData={metaData} pagename={dictionary.pagename}>
      <Banner title={dictionary.pagename} />
      <div className="page page-jobs">
        <section className="jobs_section">
          <div className="section_bg" />
          <div className="section_pagrname">{dictionary.pagename}</div>
          <Grid.Row justify="center" spacing={false}>
            <Grid.Col col={6} className="section_head">
              <h1 className="head_title">{dictionary.jobs}</h1>
              <Divider className="head_divider" />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row justify="center" spacing={false}>
            <Grid.Col col={12} md={8} xl={6}>
              <Grid.Row spacing={false}>
                {data.length ? (
                  <>
                    {data.map((entry) => {
                      const { node } = entry;
                      return (
                        <Grid.Col
                          colOffset={1}
                          col={10}
                          mdOffset={0}
                          md={6}
                          xl={4}
                          colWrap
                          key={node.id}
                        >
                          <Card className="job_card">
                            <h2 className="card_title">
                              {node.frontmatter.title}
                            </h2>
                            <p className="card_date">
                              {dictionary.date}
                              {reviseIsoString(node.frontmatter.date).whole}
                            </p>
                            <div className="text-right">
                              <Button
                                size="sm"
                                color="yellow"
                                onClick={() => {
                                  _redirectJob(node.frontmatter.title);
                                }}
                              >
                                {dictionary.detail}
                              </Button>
                            </div>
                          </Card>
                        </Grid.Col>
                      );
                    })}
                  </>
                ) : (
                  <div>{dictionary.empty}</div>
                )}
              </Grid.Row>
            </Grid.Col>
          </Grid.Row>
        </section>
      </div>
    </Layout>
  );
};

export default Jobs;
