import React from "react";
import classnames from "classnames";

const Divider = (props) => {
  const { children, className } = props;
  return (
    <div className={classnames("rvc-divider", className)}>
      <div className="divider_line" />
      {children ? (
        <>
          <div className="divider_content">{children}</div>
          <div className="divider_line" />
        </>
      ) : (
        []
      )}
    </div>
  );
};

export default Divider;
